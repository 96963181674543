import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import Image from "next/image";
interface NoImageLogoProps {
  src: string;
  value: number;
  priority?: boolean;
  roundness?: string;
  expiration_date: Date;
  sold: boolean;
  expiredSixtyDaysAgo: boolean;
  isBusiness:boolean;
}
const NoImageLogo: React.FC<NoImageLogoProps> = ({ src, value, priority, roundness, sold, expiredSixtyDaysAgo ,isBusiness}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <>
      <div
        className={twMerge(
          roundness
            ? roundness
            : window.location.pathname === "/"
              ? "rounded-xl sm:rounded-l-xl"
              : "md:rounded-[30px]",
          "relative flex h-full w-full items-center justify-center bg-[#EAAC00] ",
          (expiredSixtyDaysAgo || sold) && !isBusiness ? "opacity-50" : "opacity-90"
        )}
      >
        <p className={`absolute left-[3%] bottom-[5%] text-2xl font-bold text-white`}>${value}</p>
        <div className={`relative aspect-[1/1] h-[50%]`}>
          <Skeleton
            animation="wave"
            variant="circular"
            height="100%"
            className={twMerge(
              "absolute aspect-[1/1] object-cover",
              isImageLoaded ? "opacity-0" : "opacity-100",
            )}
          />
          {src && (
            <Image
              src={src}
              alt="Opportunity image"
              className={twMerge(
                "aspect-[1/1] rounded-full",
                isImageLoaded ? "opacity-100" : "opacity-0",
              )}
              onLoadingComplete={() => setIsImageLoaded(true)}
              fill
              priority={!!priority}
              style={{ objectFit: "cover" }}
            />
          )}
        </div>
      </div>
      <div>
        {(expiredSixtyDaysAgo || sold) && !isBusiness ? (
          <div
            className={`absolute ${isImageLoaded ? "opacity-100" : "opacity-0"
              } bottom-[25px] right-[12px] p-2 h-[45px] w-[100px] flex justify-center items-center bg-black`}
            style={{ borderRadius: "6px" }}
          >
            <p className="text-white">{sold ? "Sold Out" : "Expired"}</p>
          </div>
        ) : (null)}
      </div>
    </>
  );
};
export default NoImageLogo;