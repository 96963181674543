import { OpportunityPreview } from "../../types/models";
import OpportunityCard from "./OpportunityCard";

export default function HomeCard({
  opportunity,
  imagePriority,
}: {
  opportunity: OpportunityPreview;
  imagePriority: boolean;
}) {
  return <OpportunityCard opportunity={opportunity} imagePriority={imagePriority} />;
}
