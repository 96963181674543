import React, { useContext, useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
import {
  FeedType,
  OpportunityContext,
  OpportunityContextType,
} from "../contexts/opportunityContext";
export default function ListFilter() {
  const { setFeed, currentOption, setCurrentOption } =
    useContext<OpportunityContextType>(OpportunityContext);

  const filterList = [
    {
      index: 0,
      feed: FeedType.NEW,
      name: "New",
    },
    {
      index: 1,
      feed: FeedType.HOT,
      name: "Trending",
    },
    {
      index: 4,
      feed: FeedType.PRIME,
      name: "Best Deals",
    },
    {
      index: 2,
      feed: FeedType.AROUND,
      name: "Around",
    },
    {
      index: 3,
      feed: FeedType.JAZZED,
      name: "Favorites",
    },
    {
      index: 5,
      feed: FeedType.EVENT,
      name: "Events",
    },
    {
      index: 6,
      feed: FeedType.GIFT_CARD,
      name: "Gifts",
    },
  ];

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, [currentOption]);

  useEffect(() => {
    const isLocalStorageAvailable = window && window.localStorage;

    if (isLocalStorageAvailable) {
      localStorage.setItem("currentOption", currentOption.toString());
    }
  }, [currentOption]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setCurrentOption(0);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <div
        className="safari-home-filter fixed w-full overflow-hidden bg-[#f2f2f2] pb-3 pt-5"
        style={{
          zIndex: "3",
        }}
      >
        {loading ? (
          <div
            className={
              "filter-scroll-container mr-5 flex flex-row justify-evenly overflow-x-auto overflow-y-hidden md:mr-0 md:justify-center "
            }
            >
            {filterList.map((filter) => {
              return (
                <div key={filter.index} className={"mx-0 sm:mx-10"}>
                  <Skeleton variant="text" height={20} width={40} animation="wave"></Skeleton>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="filter-scroll-container flex min-w-full flex-row overflow-x-auto overflow-y-hidden pr-4 md:justify-center"
          >
            {filterList.map((filter) => {
              return (
                <button
                  key={filter.index}
                  className={`${
                    currentOption === filter.index
                      ? "font-bold text-black"
                      : "font-normal text-[#8F8E8E]"
                  } btn-icon ml-4 rounded-md border-2 border-solid border-[#f2f2f2] p-1 text-center font-semibold sm:ml-8 sm:text-lg md:min-w-1.5 md:hover:border-black md:hover:text-black	`}
                  onClick={() => {
                    setCurrentOption(filter.index);
                    setFeed(filter.feed);
                  }}
                >
                  <p className="whitespace-nowrap">{filter.name}</p>
                </button>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
