import React, { useState, useEffect } from 'react';

interface LoadingContentProps {
  loadingMessage: string;
}
const LoadingContent: React.FC<LoadingContentProps> = ({
  loadingMessage,
}) => {
  return (
    <div className={`fixed inset-0 bg-white flex flex-col justify-center items-center z-50`}>
      <img
        src="/logo-spinner.gif"
        alt="Logo"
        className="animate-fadeIn w-32 h-32"
      />
      <p className="mt-2 text-sm text-gray-700">
        {loadingMessage}
      </p>
    </div>
  );
};

export default LoadingContent;
